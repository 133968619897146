import FetchRequest from "@/api/FetchRequest";
class ChatroomApi {
    //基础url
    static url = "/vim/chatgpt";
   
    /**
      * 新增聊天室
      * @param  Id 助手ID
      * 
      */
    static AddChatroom(id: string): Promise<any> {
        const data={
            assistantId:id
        }
        
        return FetchRequest.post(this.url ,JSON.stringify(data) , true);
    }
     /**
      * 聊天室列表
      * 
      * 
      */
     static QueryChatroom(): Promise<any> {
      
        return FetchRequest.get(this.url+'/list' ,true, false);
    }
    /**
      * 聊天室列表历史记录
      * 
      * 
      */
    static QueryChatroomhistory(chatId:string): Promise<any> {
      
      return FetchRequest.get(this.url+'/messages/'+chatId ,true, false);
  }
      /**
      * 删除聊天室
      * 
      * 
      */
      static DeleChatroom(ids:string): Promise<any> {
      
        return FetchRequest.del(this.url+'/' +ids,'' ,true);
    }

}
export default ChatroomApi;