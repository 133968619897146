import FetchRequest from "@/api/FetchRequest";
class ChatImgApi {
    //基础url
    static url = "/api/sys/messages";
   
    /**
      * 刷新
      * @param  Id ID
      * 
      */
    static Reload(id: string): Promise<any> {
        
        return FetchRequest.put(this.url+'/refresh/'+id ,'' , true);
    }
    // 编辑助手消息
    static Reloadcontent(id: string,content:string): Promise<any> {
        
      return FetchRequest.put(this.url+'/content/'+id ,content , true);
  }
 
}
export default ChatImgApi;